import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const SharedPrediction = ({ predictions }) => {
  const { shareId } = useParams();
  const navigate = useNavigate();
  
  // Add console.log to debug
  console.log('Available predictions:', predictions);
  console.log('Looking for shareId:', shareId);
  
  const prediction = predictions.find(p => {
    console.log('Checking prediction:', p);
    console.log('Comparing shareId:', p.shareId, 'with', shareId);
    return p.shareId === shareId;
  });
  
  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };

  if (!prediction) {
    return (
      <div className="prediction-details">
        <h2>Prediction not found</h2>
        <p>Debug info:</p>
        <ul>
          <li>Share ID from URL: {shareId}</li>
          <li>Number of predictions: {predictions.length}</li>
          <li>Available shareIds: {predictions.map(p => p.shareId).join(', ')}</li>
        </ul>
        <button onClick={() => navigate('/')} className="create-prediction-btn">
          Create Your Own Prediction
        </button>
      </div>
    );
  }

  const lastUpdate = prediction.history[prediction.history.length - 1]?.date || new Date();
  const lastUpdateDate = new Date(lastUpdate);
  const today = new Date();
  const isToday = today.toDateString() === lastUpdateDate.toDateString();
  const formattedDate = isToday ? 'today' : formatDate(lastUpdateDate);

  return (
    <div className="prediction-details">
{/*      <button onClick={() => navigate('/')} className="back-button">
        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="#5F6368">
          <path d="M2.9,10.8l4.2,4.2L6,16l-6-6l6-6l1.1,1.1L2.9,9.2H12v1.5H2.9z"/>
        </svg>
        <span className="back-text">Home</span>
      </button>*/}

      <p className="small-text">Shared Prediction</p>
      <p className="small-text">Last updated {formattedDate}</p>
      <h1 className="secondary-prediction-text">
        {prediction.currentChance}% chance of {prediction.title}
      </h1>

      <div className="share-section">
        <button onClick={() => navigate('/')} className="create-prediction-btn">
          Create Your Own Prediction
        </button>
      </div>
      
      <div className="prediction-history">
        <h4>Prediction History:</h4>
        <ul className="history-list">
          {prediction.history.map((entry, index) => (
            <li key={index}>
              <strong>{entry.chance}%</strong> on {formatDate(entry.date)}
            </li>
          ))}
        </ul>
      </div>


    </div>
  );
};

export default SharedPrediction;