import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import PredictionForm from './components/PredictionForm';
import PredictionList from './components/PredictionList';
import PredictionDetails from './components/PredictionDetails';
import SharedPrediction from './components/SharedPrediction';

const App = () => {
  const [predictions, setPredictions] = useState(() => {
    // Initialize state from localStorage
    const storedPredictions = localStorage.getItem('predictions');
    return storedPredictions ? JSON.parse(storedPredictions) : [];
  });
  const navigate = useNavigate();

  // Save to localStorage whenever predictions change
  useEffect(() => {
    console.log('Saving predictions:', predictions);
    localStorage.setItem('predictions', JSON.stringify(predictions));
  }, [predictions]);

  const addPrediction = (newPrediction) => {
    const predictionWithShare = {
      ...newPrediction,
      shareId: Math.random().toString(36).substring(2, 15),
    };
    console.log('Adding new prediction:', predictionWithShare);
    setPredictions(prevPredictions => {
      const updatedPredictions = [...prevPredictions, predictionWithShare];
      // No need to manually save to localStorage here - the useEffect will handle it
      return updatedPredictions;
    });
    navigate(`/prediction/${predictionWithShare.id}`);
  };

  const updatePrediction = (updatedPrediction) => {
    setPredictions(prevPredictions => 
      prevPredictions.map(p =>
        p.id === updatedPrediction.id 
          ? { ...updatedPrediction, shareId: p.shareId }
          : p
      )
    );
  };

  const deletePrediction = (id) => {
    setPredictions(prevPredictions => 
      prevPredictions.filter(p => p.id !== id)
    );
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <PredictionForm addPrediction={addPrediction} />
              <PredictionList predictions={predictions} deletePrediction={deletePrediction} />
            </>
          }
        />
        <Route
          path="/prediction/:id"
          element={
            <PredictionDetails 
              predictions={predictions} 
              updatePrediction={updatePrediction} 
              deletePrediction={deletePrediction} 
            />
          }
        />
        <Route
          path="/share/:shareId"
          element={<SharedPrediction predictions={predictions} />}
        />
      </Routes>
    </div>
  );
};

export default App;