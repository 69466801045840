import React from 'react';
import { Link } from 'react-router-dom';

const PredictionList = ({ predictions }) => {
  return (
    <div className="prediction-listed">
      {predictions.length === 0 ? (
        <p>No predictions yet.</p>
      ) : (
        predictions.map(prediction => (
          <div key={prediction.id} className="prediction">
            <Link 
              to={`/prediction/${prediction.id}`}
              className="prediction-link"
            >
              {/* Small text on top-left */}
              <p className="small-text">
                I think there's a
              </p>
              
              {/* Main line with current chance and prediction */}
              <h3 className="main-prediction-text">
                {prediction.currentChance}% chance of {prediction.title}
              </h3>

              {/* SVG icon at the top right */}
               <svg 
                className="prediction-icon" 
                xmlns="http://www.w3.org/2000/svg" 
                height="20px" 
                viewBox="0 -960 960 960" 
                width="20px" 
                fill="#5f6368">
                <path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h264v72H216v528h528v-264h72v264q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm171-192-51-51 357-357H576v-72h240v240h-72v-117L387-336Z"/>
              </svg>
            </Link>
          </div>
        ))
      )}
    </div>
  );
};

export default PredictionList;