import React, { useState } from 'react';

const PredictionForm = ({ addPrediction }) => {
  const [title, setTitle] = useState('');
  const [chance, setChance] = useState(50);  // Percentage input

  const handleSubmit = (e) => {
    e.preventDefault();

    const newPrediction = {
      id: Date.now(), // unique ID
      title,
      initialChance: chance,
      currentChance: chance,
      history: [{ date: new Date(), chance }],
      resolved: false
    };

    addPrediction(newPrediction);

    // Reset form
    setTitle('');
    setChance(50); // Reset to 50% default
  };

  const handleChanceKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      const direction = e.key === 'ArrowUp' ? 1 : -1;
      const nextValue = Math.min(100, Math.max(0, Math.round(chance / 10) * 10 + (direction * 10)));
      setChance(nextValue);
    }
  };

  const handleChanceChange = (e) => {
    const rawValue = e.target.value;
    if (rawValue === '') {
      setChance(0);
      return;
    }

    const value = Number(rawValue);
    // Only update if it's a valid number and in range
    if (!isNaN(value) && value >= 0 && value <= 100) {
      setChance(value);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="prediction-form">
      <h1>I think the chances of</h1>
      <input
        type="text"
        className="prediction-input"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="something happening"
        required
      />
      <div className="chance-group">
        <span>is</span>
        <input
          type="number"
          className="chance-input"
          value={chance}
          onChange={handleChanceChange}
          onFocus={(e) => e.target.select()}
          onKeyDown={handleChanceKeyDown}
          min="0"
          max="100"
          step="10"
        />
        <span>%</span>
        <button type="submit" className="create-prediction-btn">Create Prediction</button>
      </div>
    </form>
  );
};

export default PredictionForm;