import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const PredictionDetails = ({ predictions, updatePrediction, deletePrediction }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const prediction = predictions.find(p => String(p.id) === id);
  const [newChance, setNewChance] = useState(prediction?.currentChance || 0);

  if (!prediction) {
    return (
      <div className="prediction-details">
        <h2>Prediction not found</h2>
        <p>This prediction may have been deleted or the link might be incorrect.</p>
        <button onClick={() => navigate('/')} className="back-button">
          Back to Home
        </button>
      </div>
    );
  }

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };

  const lastUpdate = prediction.history[prediction.history.length - 1]?.date || new Date();
  const lastUpdateDate = new Date(lastUpdate);
  const today = new Date();
  const isToday = today.toDateString() === lastUpdateDate.toDateString();
  const formattedDate = isToday ? 'today' : formatDate(lastUpdateDate);

  const handleChanceKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      const direction = e.key === 'ArrowUp' ? 1 : -1;
      const nextValue = Math.min(100, Math.max(0, Math.round(newChance / 10) * 10 + (direction * 10)));
      setNewChance(nextValue);
    }
  };

  const handleChanceChange = (e) => {
    const rawValue = e.target.value;
    if (rawValue === '') {
      setNewChance(0);
      return;
    }

    const value = Number(rawValue);
    if (!isNaN(value) && value >= 0 && value <= 100) {
      setNewChance(value);
    }
  };

  const handleUpdate = () => {
    const updatedPrediction = {
      ...prediction,
      currentChance: newChance,
      history: [...prediction.history, { date: new Date(), chance: newChance }]
    };
    updatePrediction(updatedPrediction);
  };

  const handleDelete = () => {
    const confirmed = window.confirm('Are you sure you want to delete this prediction? This action cannot be undone.');
    if (confirmed) {
      deletePrediction(prediction.id);
      navigate('/');
    }
  };

  const handleShare = () => {
    const shareUrl = `${window.location.origin}/share/${prediction.shareId}`;
    navigator.clipboard.writeText(shareUrl);
    alert('Share link copied to clipboard!');
  };

  return (
    <div className="prediction-details">
      <button onClick={() => navigate('/')} className="back-button">
        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="#5F6368">
          <path d="M2.9,10.8l4.2,4.2L6,16l-6-6l6-6l1.1,1.1L2.9,9.2H12v1.5H2.9z"/>
        </svg>
        <span className="back-text">Back</span>
      </button>

      <p className="small-text">As of {formattedDate}, I think there's a</p>
      <h1 className="secondary-prediction-text">{prediction.currentChance}% chance of {prediction.title}</h1>

      <p className="small-text">Right now I think there's a</p>
      <div className="chance-group">
        <input
          type="number"
          className="chance-input"
          value={newChance}
          onChange={handleChanceChange}
          onFocus={(e) => e.target.select()}
          onKeyDown={handleChanceKeyDown}
          min="0"
          max="100"
          step="10"
        />
        <span>% chance</span>

        <button onClick={handleUpdate} className="update-prediction-btn">Update Prediction</button>
      </div>

      <div className="share-section">
        <button onClick={handleShare} className="share-prediction-btn">
          Share Prediction
        </button>
      </div>

      <div className="prediction-history">
        <h4>Prediction History:</h4>
        <ul className="history-list">
          {prediction.history.map((entry, index) => (
            <li key={index}>
              <strong>{entry.chance}%</strong> on {formatDate(entry.date)}
            </li>
          ))}
        </ul>
      </div>

      <button onClick={handleDelete} className="delete-prediction-btn">
        Delete Prediction
      </button>
    </div>
  );
};

export default PredictionDetails;